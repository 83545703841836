<template>
  <div class="container-fluid p-0 m-0">
    <div class="preloader" v-if="!loaded"><div class="loadingImg"></div></div>
    <div class="gamecon gamecon-bgimg">
      <div class="gamecon-inner text-center">
        <h5>{{ taleName }}</h5>
        <div class="difficulty" v-if="loaded">
          <h6 v-if="!authed.customer.team_name || showEdit">Set your team name</h6>
          <h6 v-else>Team name</h6>
          <div class="setTeam group" v-if="!authed.customer.team_name || showEdit">
            <div class="inputContainer">
              <input class="form-control" type="text" required name="code" v-model="teamName" v-bind:class="{ fieldError: fieldError }">
            </div>
            <div class="btnContainer">
              <button class="btn btn-primary btn-block w-100" type="button" v-on:click="setName()">SET</button>
            </div>
          </div>
          <div v-else>
            <h6 class="teamName">{{ authed.customer.team_name}} <span class="pencil" v-on:click="editName()"></span></h6>
          </div>

          <h6 v-if="authed.customer.team_name && authed.show_casual && authed.show_standard">Choose your difficulty</h6>
          <div v-if="authed.customer.team_name">
            <div class="sectionContainer group" v-if="authed.show_casual && authed.show_standard">
              <div class="section">
                <a v-bind:href="'https://team-experiences.online/player?ref=' + authed.customer.reference + '&tale=' + authed.customer_tale.reference + '&difficulty=1'" class="playBtn">{{ authed.affiliate.casual_label }}</a>
              </div>
              <div class="section">
                <a v-bind:href="'https://team-experiences.online/player?ref=' + authed.customer.reference + '&tale=' + authed.customer_tale.reference + '&difficulty=2'" class="playBtn">{{ authed.affiliate.standard_label }}</a>
              </div>
            </div>
            <div class="sectionContainer group" v-else>
              <div v-if="authed.show_casual">
                <a v-bind:href="'https://team-experiences.online/player?ref=' + authed.customer.reference + '&tale=' + authed.customer_tale.reference + '&difficulty=1'" class="playBtn">Play game</a>
              </div>
              <div v-if="authed.show_standard">
                <a v-bind:href="'https://team-experiences.online/player?ref=' + authed.customer.reference + '&tale=' + authed.customer_tale.reference + '&difficulty=2'" class="playBtn">Play game</a>
              </div>
            </div>
          </div>
        </div>

        <a href="#" v-on:click.stop.prevent="clearAuth()" class="goBack">Go back and enter another code</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountContainer',
  data () {
    return {
      loaded: false,
      authed: null,
      showPlayOptions: null,
      taleName: null,
      teamName: null,
      showEdit: false,
      fieldError: false,
      processing: false,
    }
  },
  props: {
  },
  computed: {
    getAuth () {
      return this.$store.getters.getAuth
    },
    ...mapGetters(['getAuth']),
  },
  methods: {
    editName() {
      this.showEdit = true
    },
    setName() {
      this.loaded = false
      this.fieldError = false

      if (this.teamName && !this.processing) {
        this.processing = true
        axios
            .post('https://team-experiences.online/api/codeget/' + this.authed.reference, { team_name: this.teamName.trim() })
            .then(response => {
              console.log("response", response.data)
              this.authed.customer.team_name = this.teamName.trim()
              this.loaded = true
              this.showEdit = false
              this.processing = false
            })
            .catch(error => {
              this.loaded = true
              this.processing = false
              console.log(error)
            })
      } else {
        this.fieldError = true
      }
    },
    clearAuth() {
      sessionStorage.removeItem('Team_GameReference')
      this.$cookies.remove('Team_GameReference')
      this.$store.dispatch("setAuth", null)
      this.$router.push({ name: 'home' })
    }
  },
  mounted: function() {
    this.authed = this.getAuth
    this.taleName = this.authed.tale.name

    if (!this.authed) {
      let gameReference = sessionStorage.getItem('Team_GameReference')
      if (!gameReference && this.$cookies.isKey('Team_GameReference')) {
        console.log("no game reference")
        gameReference = this.$cookies.get('Team_GameReference')
      }

      if (gameReference) {
        axios
          .get('https://team-experiences.online/api/codeget/' + gameReference)
          .then(response => {
            this.loaded = true
            console.log("authed", response.data)
            this.authed = response.data;
            this.teamName = this.authed.customer.team_name
            this.$store.dispatch("setAuth", response.data)
          })
          .catch(error => {
            console.log(error)
            this.clearAuth()
            this.$router.push({ name: 'home' })
          })
      } else {
        this.clearAuth()
        this.$router.push({ name: 'home' })
      }

    } else {
      this.loaded = true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
