<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      loaded: false,
      auth: null,
    }
  },
  computed: {
    getRouteName () {
      return this.$route.name
    },
    getAuth () {
      return this.$store.getters.getAuth
    },
    ...mapGetters(['getAuth']),
  },
  methods: {
  },
  watch: {
  },
  mounted: function () {
    this.$cookies.config('7d')
  },
  destroyed: function () {

  },

}
</script>

<style>
</style>