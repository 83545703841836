<template>
  <div class="auth-bg">
    <div class="preloader" v-if="loading"><div class="loadingImg"></div></div>
    <div class="authentication-box">
      <div class="card mt-4">
        <div class="card-body">
          <div class="text-center">
            <h4>Enter code</h4>
          </div>
          <form class="theme-form" @submit="submitForm">
            <div class="mb-3">
              <input class="form-control" type="text" required name="code" v-model="codeForm.data.code">
            </div>
            <div class="form-row mt-3">
              <p class="formError" v-if="this.codeForm.validation.main">{{ this.codeForm.validation.main }}</p>
              <button class="btn btn-primary btn-block w-100" type="submit">SUBMIT</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HomeContainer',
  props: {
  },
  data () {
    return {
      codeForm: {
        data: {
          code: this.$developer ? "test" : null,
        },
        validation: {
          code: ['required'],
          main: null
        },
        error_messages: {},
        status: null
      },
      loading: false,
    }
  },
  methods: {
    submitForm: function (e) {
      this.codeForm.status = "loading";
      this.loading = true;
      axios
          .post('https://team-experiences.online/api/codecheck', { code: this.codeForm.data.code.trim() })
          .then(response => {
            console.log("response", response)
            sessionStorage.setItem('Team_GameReference', response.data.gameCode.reference);
            this.$cookies.set('Team_GameReference', response.data.gameCode.reference, 60 * 60 * 24);
            this.$store.dispatch("setAuth", response.data.gameCode)

            setTimeout(() => {
              this.$router.push({ name: 'game' })
            }, 2000);
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            this.codeForm.status = "error";
            this.codeForm.validation.main = "The game code isn't valid"
          })
      e.preventDefault();
    },
  },
  mounted: function() {

  },
  beforeDestroy: function() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
