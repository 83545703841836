import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeContainer from './containers/HomeContainer.vue'
import AccountContainer from './containers/AccountContainer.vue'

Vue.use(VueRouter);

const routes = [
  { path: '/',  name: 'home', component: HomeContainer },
  { path: '/game', name: 'game', component: AccountContainer },
  //{ path: '*', name: 'not_found', component: NotFoundContainer },

]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if(to || from)
      window.scrollTo(0, 0)

      let gameReference = sessionStorage.getItem('Team_GameReference')
      if (!gameReference && Vue.$cookies.isKey('Team_GameReference'))
        gameReference = Vue.$cookies.get('Team_GameReference')

    if(to && (to.name == "game") && !gameReference)
      next({ name: 'home' })

    next();
})

export default router