import Vue from 'vue'
import VueCookies from 'vue-cookies'

import axios from 'axios'
//import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";

import store from './store'
import router from './routes.js'

import App from './App.vue'


Vue.config.productionTip = false

axios.defaults.headers.common['X-API-KEY'] = "HbzxE8RVpV8pLBh0cMTI01ZCIHWlrMhMMMcwzfFSrj22o2GBOfnNcJhF8YwaSDt8"

Vue.prototype.$developer = window.developer

/*
Sentry.init({
  Vue,
  dsn: "https://c9ef3953d32d470dba1cdc985942ba40@o121274.ingest.sentry.io/5996874",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "cursed-tales.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
*/

Vue.mixin({
  data () {
    return {
      mfq: []
    }
  },
  methods: {
    checkForMfq: function () {
      this.mfq = window._mfq || []
    },
    pushMfqVariable: function (variable, value) {
      this.checkForMfq()
      this.mfq.push(["setVariable", variable, value])
      console.log("mfq variable set")
    }
  }
})

Vue.use(VueCookies)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#wrapper')
